import { useState, useEffect } from 'react'

export const apiUrl =
  'https://web-cms.superloop.com/api/collections/partials/entries/802fdde8-fdac-47e0-b049-0402bbb3610d'

const useFetchOutages = () => {
  const [data, setData] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(apiUrl).then(r => r.json())
        setData(response.data.page_builder || [])
      } catch (err) {
        console.error('Error fetching data:', err)
      }
    }

    fetchData()
  }, [apiUrl])

  return data
}

export default useFetchOutages
