import React from 'react'
import Layout from '../components/Layout'
import useFetchOutages from '../services/useFetchOutages'

const Outages = () => {
  const content = useFetchOutages()

  return (
    <Layout>
      <section className="outage">
        <div className="container">
          <h1 class="page-title">Major Outages</h1>
          <div className="blog-post-content">
            <p style={{ margin: '0 auto 48px' }}>
              The status of our network and that of our wholesalers is shown
              below.
            </p>

            {content.map((block, index) => (
              <div
                key={index}
                dangerouslySetInnerHTML={{ __html: block.content }}
              />
            ))}

            <p style={{ margin: '48px auto 16px' }}>
              For further information on how we report major outages, please
              refer to our{' '}
              <a
                href="/pdf/tomi-communication-of-major-outages.pdf"
                target="_blank"
              >
                Major Outage Communications Procedure
              </a>
              .
            </p>

            <p>
              <strong>Our Wholesalers</strong>
            </p>
            <p className="mt-3">
              For information on outages affecting the nbn® and Opticomm
              customers, please visit the links below:
            </p>
            <ul className="mt-3">
              <li>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.nbnco.com.au/support/network-status"
                >
                  nbn® outages
                </a>
              </li>
              <li>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://online.telco.opticomm.com.au/network"
                >
                  Opticomm outages
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </Layout>
  )
}
export default Outages
